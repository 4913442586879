import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogActions } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-dev-env-message',
  standalone: true,
  imports: [MatDialogActions, FormsModule, MatCheckbox],
  templateUrl: './dev-env-message.component.html',
  styleUrl: './dev-env-message.component.css',
})
export class DevEnvMessageComponent {
  savePreference: boolean = false;
  @Output() savePreferenceChange = new EventEmitter<boolean>();

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) protected data
  ) {
    this.dialogRef.disableClose = true;
  }

  onYesClick(): void {
    this.savePreferenceChange.emit(this.savePreference);
  }
}
