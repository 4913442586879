<div class="title-bar">
  <h2>Dev Environment</h2>
</div>
<div class="content">
  <p>This is the development version of the Risk Information Portal MVP.</p>
  <p>It is incomplete and susceptible to breakages and regressions as new features are being developed.</p>
</div>
<mat-dialog-actions class="actions footer" align="end">
  <mat-checkbox class="accept-box" [(ngModel)]="savePreference" labelPosition="before">Don't warn me again for 7 days</mat-checkbox>
  <button class="bg-green-500 hover:bg-green-700 text-white py-2 px-4 border border-green-500 rounded"
          (click)="onYesClick()">I Understand</button>
</mat-dialog-actions>
