import { Directive, ElementRef, HostListener, ComponentRef, ViewContainerRef, inject } from '@angular/core';
import { PasswordComplexityComponent } from '../ui/password-complexity/password-complexity.component';
import { ComponentFactory } from '../services/ComponentFactory/component-factory.service';

@Directive({
  selector: '[appPasswordPopover]',
  standalone: true,
})
export class PasswordPopoverDirective {
  private popover: ComponentRef<PasswordComplexityComponent>;
  private componentFactory:ComponentFactory = inject(ComponentFactory);

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {}

  @HostListener('focus') onFocus() {
    this.popover = this.componentFactory.appendComponentToBody(PasswordComplexityComponent);
    this.popover.instance.password.set(this.el.nativeElement.value);
    this.setPosition();
  }

  @HostListener('input') onInput() {
    this.popover.instance.password.set(this.el.nativeElement.value);
  }

  @HostListener('blur') onBlur() {
    if (this.popover) {
      this.popover.destroy();
    }
  }

  private setPosition() {
    const { top, left, height, right, width } = this.el.nativeElement.getBoundingClientRect();
    const popoverEl = this.popover.location.nativeElement;
    const popoeverSize = popoverEl.getBoundingClientRect()

    popoverEl.style.top = `${top - window.scrollY + height / 2 - popoeverSize.height / 2}px`;
    
    popoverEl.style.left = `${right + window.scrollX + 30}px`;
    popoverEl.style.position = 'absolute';
    popoverEl.style.zIndex = '999999';

  }
}
