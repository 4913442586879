<div class="title-bar">
  <div class="title">Delete {{data.type}}</div>
  <div class="window-controls">
      <button ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <p>Are you sure you want to delete <b>{{data.details}}</b>?</p>
</div>
<div class="footer">
  <button class="bg-slate-500 hover:bg-slate-700 text-white py-2 px-2 border border-slate-500 rounded w-32"
          (click)="onCancel()">Cancel</button>
  <button class="bg-red-500 hover:bg-red-700 text-white py-2 px-2 border border-red-500 rounded w-32"
          (click)="onConfirm()">Yes</button>
</div>
