import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';

export class DeleteEvent {
  delete: boolean;

  constructor(shouldDeleted) {
    this.delete = shouldDeleted;
  }

  static delete() {
    return new DeleteEvent(true);
  }

  static cancel() {
    return new DeleteEvent(false);
  }
}

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent {
  @Output() deleteEvent = new EventEmitter<DeleteEvent>();

  constructor(
    public dialogRef: DialogRef<DeleteConfirmationComponent>,
    @Inject(DIALOG_DATA) public data: { type: string; details: string }
  ) {
    if (!this.data) {
      this.data = { type: '{{entityType}}', details: '{{EntityDetails}}' };
    }
  }

  onCancel(): void {
    this.deleteEvent.emit(DeleteEvent.cancel());
  }

  onConfirm(): void {
    this.deleteEvent.emit(DeleteEvent.delete());
  }
}
