import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Dialog } from '@angular/cdk/dialog';
import { tap } from 'rxjs';
import { CustomDialogContainer } from './components/custom-dialog-container.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { DevEnvMessageComponent } from './components/dev-env-message/dev-env-message.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly customDialog = inject(Dialog);

  constructor() {}

  showConfirmDelete(entityType: string, entityDetails: string) {
    let ref = this.customDialog.open(DeleteConfirmationComponent, {
      container: CustomDialogContainer,
      data: { type: entityType, details: entityDetails },
    });

    ref.disableClose = true;

    return ref.componentInstance.deleteEvent.pipe(
      tap((data) => {
        ref.close();
      })
    );
  }

  showComponent<T>(
    component: ComponentType<T>,
    data,
    panelClass: string = 'standard-panel-background'
  ) {
    let ref = this.customDialog.open(component, {
      panelClass: panelClass,
      container: CustomDialogContainer,
      data: data,
    });

    ref.disableClose = true;

    return ref.componentInstance;
  }

  showConfirmation(displayText: string) {
    return this.dialog
      .open(ConfirmationComponent, {
        data: { displayText: displayText },
      })
      .afterClosed();
  }

  showDevMessage() {
    let ref = this.customDialog.open(DevEnvMessageComponent, {
      container: CustomDialogContainer,
    });

    ref.disableClose = true;

    return ref.componentInstance.savePreferenceChange.pipe(
      tap((data) => ref.close())
    );

    return this.dialog
      .open(DevEnvMessageComponent, {
        data: { messsage: 'test' },
      })
      .afterClosed();
  }
}
